import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'
import MailTo from "../../components/mail-to"

const cookieTable = [
  {
    name: 'Billsby.app',
    purpose: 'To handle functionality of the Billsby control panel',
    time: '',
  },
  {
    name: 'billsby_auth_data_control',
    purpose: 'Authentication token to handle session control and login to the Billsby web application',
    time: 'Session',
  },
  {
    name: 'billsby_selected_company',
    purpose: 'Authentication token to handle company switching wthin the Billsby web application',
    time: 'Session',
  },
  {
    name: 'Facebook',
    purpose: 'Retargeting and advertising',
    time: '',
  },
  {
    name: 'fr',
    purpose: 'Used by Facebook to deliver series of advertisement products such as real-time bidding from third-party advertisers',
    time: '3 months',
  },
  {
    name: 'tr',
    purpose: 'Pending',
    time: 'Session',
  },
  {
    name: 'Google Analytics',
    purpose: 'Performance and analysis',
    time: '',
  },
  {
    name: 'collect',
    purpose: 'Sends data to Google Analytics about the visitor’s device and behavior. Tracks the visitor across devices and marketing channels.',
    time: 'Session',
  },
  {
    name: 'ga',
    purpose: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
    time: '2 years',
  },
  {
    name: 'gat',
    purpose: 'Used by Google Analytics to throttle request rate.',
    time: 'Session',
  },
  {
    name: 'gid',
    purpose: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
    time: 'Session',
  },
  {
    name: 'IDE',
    purpose: 'Used by Google DoubleClick to register and report the website user’s actions after viewing or clicking one of the advertiser’s ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.',
    time: '1 year',
  },
  {
    name: 'Hotjar',
    purpose: 'Performance and analysis',
    time: '',
  },
  {
    name: '_hjClosedSurveyInvites',
    purpose: 'Hotjar cookie. This cookie is set once a visitor interacts with a Survey invitation modal popup. It is used to ensure that the same invite does not re-appear if it has already been shown.',
    time: '365 days',
  },
  {
    name: '_hjDonePolls',
    purpose: 'Hotjar cookie. This cookie is set once a visitor completes a poll using the Feedback Poll widget. It is used to ensure that the same poll does not re-appear if it has already been filled in.',
    time: '365 days',
  },
  {
    name: '_hjMinimizedPolls',
    purpose: 'Hotjar cookie. This cookie is set once a visitor minimizes a Feedback Poll widget. It is used to ensure that the widget stays minimizes when the visitor navigates through your site.',
    time: '365 days',
  },
  {
    name: '_hjDoneTestersWidgets',
    purpose: 'Hotjar cookie. This cookie is set once a visitor submits their information in the Recruit User Testers widget. It is used to ensure that the same form does not re-appear if it has already been filled in.',
    time: '365 days',
  },
  {
    name: '_hjMinimizedTestersWidgets',
    purpose: 'Hotjar cookie. This cookie is set once a visitor minimizes a Recruit User Testers widget. It is used to ensure that the widget stays minimizes when the visitor navigates through your site.',
    time: '365 days',
  },
  {
    name: '_hjIncludedInSample',
    purpose: 'Hotjar cookie. This session cookie is set to let Hotjar know whether that visitor is included in the sample which is used to generate funnels.',
    time: '365 days',
  },
  {
    name: '_hjShownFeedbackMessage',
    purpose: 'This cookie is set when a visitor minimizes or completes Incoming Feedback. This is done so that the Incoming Feedback will load as minimized immediately if they navigate to another page where it is set to show.',
    time: '365 days',
  },
  {
    name: 'Intercom',
    purpose: 'Customer service and experience management',
    time: '',
  },
  {
    name: 'intercom-id-*',
    purpose: 'Unique anonymous identifier.',
    time: '9 months',
  },
  {
    name: 'intercom-session-*',
    purpose: 'Keeps track of logged in sessions.',
    time: '',
  },
  {
    name: 'Quora',
    purpose: 'Retargeting and advertising',
    time: '',
  },
  {
    name: 'm-b',
    purpose: 'Used by Quora to deliver series of advertisement products such as real-time bidding from third-party advertisers',
    time: '2 years',
  },
]

const CookiePolicy = () => {
  return (
    <Layout>
      <SEO 
        title="Cookie Policy" 
        description="Understand what cookies we use to deliver our services to you." 
        url="https://www.billsby.com/privacy/cookie-policy"
      />
      
      <div className="default-template">
        <div className="section-tab">
          <div className="container">
            <ul className="tab-wrapper">
              <li className="tab-item"><Link to="/terms">Terms and conditions</Link></li>
              <li className="tab-item" to="/privacy"><Link>Privacy policy</Link></li>
              <li className="tab-item"><Link to="/privacy/dpa">EU Data Processing Addendum</Link></li>
              <li className="is-active tab-item"><Link to="/privacy/cookie-policy">Cookie policy</Link></li>
              <li className="tab-item"><Link to="/privacy/sub-processors">Sub-processors</Link></li>
              <li className="tab-item"><Link to="/terms/acceptable-use">Acceptable use policy</Link></li>
            </ul>

            <p className="date">EFFECTIVE 1ST AUGUST 2019</p>
            <h1 className="section-heading">Cookie policy</h1>
          </div>
        </div>

        <div className="container default-content">
          <p>Please remember that your use of the Services is at all times subject to the terms and conditions and privacy policy, which incorporate this cookie policy. Any terms we use in this Cookie Policy without defining them have the definitions given to them in the terms and conditions and privacy policy, linked above.</p>
          <h4>Cookie policy</h4>
          <p>The <strong>services</strong> use cookies and similar technologies such as pixel tags, web beacons, clear GIFs, JavaScript, and local storage. Cookies are small files – usually consisting of letters and numbers – placed on your computer, tablet, phone, or similar device, when you use that device to visit a Site. We will refer to cookies and similar technologies simply as <strong>“cookies”</strong> for the remainder of this cookie policy. We use <strong>cookies</strong> to enable our servers to recognize your web browser and tell us how and when you visit and otherwise use the <strong>services</strong> through the internet, to analyze trends, and to learn about our user base.</p>
          
          <p>We may combine non-personally identifiable information collected through <strong>cookies</strong> with other <strong>personal information</strong> that we have about you, for example, to tell us who you are or whether you have an account. We may also supplement the information we collect from you with information received from third parties in order to enhance our <strong>services,</strong> or to offer you information that we believe may be of interest to you. Where we use <strong>cookies</strong> to collect information that is personally identifiable, or that can become personally identifiable if we combine it with other information, the Billsby Privacy Policy will apply in addition to this cookie policy.</p>
          
          <p>You can control and/or delete <strong>cookies</strong> as you wish – for details, see <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org/</a>. Most browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new <strong>cookies,</strong> as well as (depending on the sophistication of your browser software) allowing you to decide on acceptance of each new cookie in a variety of ways. You can delete all <strong>cookies</strong> that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work. We strongly recommend that you leave <strong>cookies</strong> active, because they enable you to take advantage of the most attractive features of the <strong>services.</strong></p>
          
          <p>Further information on Cookies can be found at <a href="http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm" target="_blank" rel="noopener noreferrer">http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm</a>, <a href="https://ico.org.uk/for-the-public/online/cookies/" target="_blank" rel="noopener noreferrer">https://ico.org.uk/for-the-public/online/cookies/</a>, or <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org/</a>.</p>
          
          <p>Please reference the chart and general description below to learn more about the kinds of <strong>cookies</strong> we use and for what purpose.</p>
          
          <h4>What types of cookies do we use and for what purposes?</h4>
          
          <table class="table">
            <thead>
              <th>Cookie name</th>
              <th>Purpose</th>
              <th>Expiration time</th>
            </thead>
            <tbody>
              {
                cookieTable.map((cookitList, i) => (
                  <tr>
                    <td data-attr="Cookie name">{cookitList.name}</td>
                    <td data-attr="Purpose">{cookitList.purpose}</td>
                    <td data-attr="Expiration time">{cookitList.time}</td>
                  </tr>
                ))
              }
              </tbody>
            </table>
                
                
                <h4>Essential cookies</h4>
                <p>These are <strong>cookies</strong> that are required to provide you with features or services that you have requested. For example, <strong>cookies</strong> that enable you to log into secure areas of our site or use a shopping cart. Disabling these <strong>cookies</strong> will encumber the <strong>services’</strong> performance and may make certain features and services unavailable.</p>
                
                <h4>Functionality cookies</h4>
                <p>These <strong>cookies</strong> are used to recognize you when you return to our <strong>services.</strong> This enables us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).</p>
                
                <h4>Performance or analytical cookies</h4>
                <p>Performance or analytical <strong>cookies</strong> allow us to understand how visitors use our <strong>website</strong> and <strong>service</strong> such as by collecting information about the number of visitors to the <strong>website,</strong> what pages visitors view on our <strong>website</strong> and how long visitors are viewing pages on the <strong>website.</strong> Performance or analytical <strong>cookies</strong> also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the <strong>service’s</strong> content for those who engage with our advertising.</p>
                
                <h4>Retargeting or advertising cookies</h4>
                <p>Retargeting or advertising <strong>cookies</strong> collect data about your online activity and identify your interests so that we can provide advertising that we believe is relevant to you.</p>
                <h4>Third party cookies</h4>
                <p>These are <strong>cookies</strong> placed by a third party on your device and may provide information to those third parties about browsing habits (including your visits to our Services, the pages you have visited and the links and advertisements you have clicked) that may help identify if certain services that such third parties provide are being used, identify your interests, retarget you and serve advertisements that are relevant to you. We do not have control or access to such Cookies. If you look at one page on our Services, an advertisement may be delivered to you on our Services or on other sites, for products referenced on that page or for similar products and services. We do not engage in retargeted advertising for children users of our Services. Opt-out options are also available on the websites of the <a href="http://www.networkadvertising.org/" target="_blank" rel="noopener noreferrer">Network Advertising Initiative (NAI)</a>, the <a href="http://www.aboutads.info/" target="_blank" rel="noopener noreferrer">Digital Advertising Alliance (DAA)</a> and the <a href="http://www.youronlinechoices.com/" target="_blank" rel="noopener noreferrer">European Digital Advertising Alliance (eDAA)</a>.</p>
                <h4>Session cookies and persistent cookies</h4>
                <p>The types of <strong>cookies</strong> described above may be “session <strong>cookies</strong>” which are temporary <strong>cookies</strong> that are only stored on your device while you are visiting our site or “persistent <strong>cookies</strong>” which are <strong>cookies</strong> that are stored on your device for a period of time after you leave our site. The length of time a persistent <strong>cookie</strong> stays on your device varies from <strong>cookie</strong> to <strong>cookie.</strong> We use persistent <strong>cookies</strong> to store your preferences so that they are available for the next visit, and to keep a more accurate account of how often you visit our <strong>services,</strong> how often you return, how your use of the <strong>services</strong> may vary over time, and measure the effectiveness of advertising efforts. Through these <strong>cookies,</strong> we may collect information about your online activity after you leave our <strong>services.</strong> Just like any other usage information we collect, this information allows us to improve the <strong>services</strong> and customize your online experience. Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities over time and across different websites. Our <strong>services</strong> do not support Do Not Track requests at this time, which means that we collect information about your online activity both while you are using the <strong>services</strong> and after you leave the <strong>services</strong>.</p>
                
                <h4>What is the Google Analytics cookie and how is it used?</h4>
                <p>Our <strong>services</strong> use Google Analytics, a web analysis service provided by Google Inc. <strong>(“Google”)</strong> which is based on cookie technology. The information generated by the cookie is usually sent to a <strong>Google</strong> server in the USA, where it is stored. On behalf of Billsby, <strong>Google</strong> will use the generated information to evaluate your use of the website, to compile reports on website activities, and to provide the website operator with additional services connected with website and Internet use. The IP address transmitted by your browser in connection with Google Analytics is not collated with other data by <strong>Google.</strong> To opt out of being tracked by Google Analytics across all websites visit <a href="http://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout</a>.</p>
                
                <h4>Cookie settings</h4>
                <p>You can decide whether or not to accept Cookies. One way you can do this is through your internet browser’s settings. To find our more information about Cookies, including information about how to manage and delete Cookies, please visit <a href="https://ico.org.uk/for-the-public/online/cookies/" target="_blank" rel="noopener noreferrer">https://ico.org.uk/for-the-public/online/cookies/</a> or <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org/</a>. To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your browser’s menu.</p>
                <p>If you have any questions about our cookie policy, please don’t hesitate to contact us on {MailTo({classes: ''})}.</p>
        </div>
      </div>
    </Layout>
  )
}

export default CookiePolicy